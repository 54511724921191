<template>
  <div class="template-wrap" ref="wrap" @dblclick="toggleExpand">
    <div class="top-wrap" :class="{ expand: !isExpand }" @click.stop="">
      <div
        class="item"
        v-for="(item, index) in reverMenu"
        :key="item.id"
        :class="{ act: currentActIdx + index == reverMenu.length - 1 }"
        @click.stop="selectAct(reverMenu.length - index - 1)"
      >
        <span>{{ item.label }}</span>
        <div class="item-opt">
          <van-icon
            :name="!item.enable ? 'closed-eye' : 'eye'"
            @click.stop="toggleShow(reverMenu.length - index - 1)"
          />
          <van-icon
            name="delete-o"
            class="delete-icon"
            @click.stop="deletelayer(reverMenu.length - index - 1)"
          />
        </div>
      </div>
      <div class="opt">
        <div class="opt-item up" @click.stop="up"><van-icon name="down" /></div>
        <div class="opt-item" @click.stop="add"><van-icon name="plus" /></div>
        <div class="opt-item" @click.stop="down"><van-icon name="down" /></div>
        <div class="opt-item" @click.stop="showSet">
          <van-icon name="setting-o" />
        </div>
      </div>
    </div>
    <div class="save" @click="save">保存</div>
    <div class="content" ref="content" :style="contentStyle">
      <template v-for="(item, index) in menus">
        <template v-if="item.source && item.source.length && item.enable">
          <div
            v-if="item.type === 'image'"
            class="wrap"
            :class="{ select: currentActIdx === index }"
            :style="parseStyle(item)"
            :key="index"
          >
            <img :src="item.source[0].url" alt="" />
          </div>
          <div
            v-if="item.type === 'video' || item.type === 'raw_video'"
            class="wrap"
            :class="{ select: currentActIdx === index }"
            :style="parseStyle(item)"
            :key="index"
          >
            <img
              :src="
                'https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/webp/' +
                item.source[0].hash +
                '.webp'
              "
            />
          </div>
        </template>
        <div
          v-else-if="item.type === 'camera' && item.enable"
          class="wrap camera"
          :class="{ select: currentActIdx === index }"
          :style="parseStyle(item)"
          :key="index"
        ></div>
      </template>
    </div>
    <van-popup
      v-model="isShowSet"
      overlay-class="set-overlay"
      position="bottom"
      @close="closePop"
      class="set-pop-wrap"
    >
      <div class="setting-wrap">
        <div class="opt-wrap">
          <div class="scale">
            <span class="label">缩放</span
            ><van-stepper
              v-model="scale"
              @change="onScaleChange"
              :step="0.01"
              :min="0"
              integer
            />
          </div>
          <div class="scale">
            <span class="label">左右</span
            ><van-stepper
              v-model="layerLeft"
              @change="onLeftChange"
              :step="1"
              :min="-720"
              integer
            />
          </div>
          <div class="scale">
            <span class="label">上下</span
            ><van-stepper
              v-model="layerTop"
              @change="onTopChange"
              :step="1"
              :min="-720"
              integer
            />
          </div>
        </div>
        <div class="tabs" v-if="optLayer.type !== 'camera'">
          <div
            v-for="item in tabs"
            class="item"
            :key="item.value"
            :class="{ act: sourceType === item.value }"
            @click="changeSourceType(item)"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="source" v-if="optLayer.type !== 'camera'">
          <template v-for="item in layers">
            <div
              class="item-wrap"
              :key="item.id"
              @click="selLayer(item)"
              :class="{
                act:
                  optLayer.source && optLayer.source[0]
                    ? optLayer.source[0]._id === item.source._id
                    : false,
              }"
            >
              <img
                v-if="item.source.mime.includes('image/')"
                v-lazy="item.source.url"
                :alt="item.source.name"
              />
              <img
                v-else-if="item.source.mime.includes('video/')"
                v-lazy="
                  'https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/webp/' +
                  item.source.hash +
                  '.webp'
                "
              />
            </div>
          </template>
        </div>
      </div>
    </van-popup>
    <!-- <van-popup v-model="isShowPreview" :overlay="false" class="prew-wrap">
      <van-button class="cancel" @click="cancel" size="mini">返回</van-button>
      <van-button class="save" @click="save" type="info" size="mini"
        >保存</van-button
      >
      <div class="preview-content" :style="{ scale: previewScale }"></div>
    </van-popup> -->
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
const FILTER_TYPE = ["image", "video", "camera", "raw_video"];
export default {
  name: "Template",
  data() {
    return {
      contentScale: 1,
      oldScale: 0,
      currentActIdx: 0,
      menus: [],
      isShowSet: false,
      sourceType: "W",
      scale: 1,
      isShowPreview: false,
      previewScale: 1,
      isExpand: true, // 图层是否展示
      layers: [], //资源
      layerLeft: 0, // 当前图层的左右边距
      layerTop: 0, // 当前图层的上下边距
    };
  },
  created() {
    let html = document.querySelector("html");
    html.style.fontSize = `${html.clientWidth / 7.2}px`;
    this.setData();
    window.onresize = () => {
      this.$nextTick(() => {
        this.contentScale =
          (this.$refs.wrap.clientHeight / this.$refs.content.clientHeight) *
          0.98;
        console.log(">>>resize", this.contentScale);
      });
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.contentScale =
        (this.$refs.wrap.clientHeight / this.$refs.content.clientHeight) * 0.98;
      console.log(">>>> wrap clientHeight", this.$refs.wrap.clientHeight);
      console.log(">>>> content clientHeight", this.$refs.content.clientHeight);
      console.log(">>>> contentScale", this.contentScale);
    });
  },
  computed: {
    ...mapState(["accountInfo", "pid"]),
    contentStyle() {
      return {
        transform: `scale(${this.contentScale}) translate3d(-50%, 0, 0)`,
      };
    },
    tabs() {
      const menus = [
        { label: "背景", value: "W" },
        { label: "挂件", value: "P" },
        { label: "人像", value: "H" },
        { label: "摆件", value: "O" },
        { label: "前景", value: "F" },
        { label: "横幅", value: "B" },
        { label: "复合类型", value: "X" },
      ];
      let temp = this.menus[this.currentActIdx];
      if (temp.id && temp.layer) {
        return [];
      } else {
        return menus;
      }
    },
    optLayer() {
      return this.menus[this.currentActIdx];
    },
    reverMenu() {
      let temp = [];
      this.menus.forEach((item) => temp.unshift(item));
      return temp;
    },
  },
  watch: {
    pid() {
      if (this.accountInfo) {
        this.setData();
      }
    },
  },
  methods: {
    ...mapMutations(["setCurrentPid"]),
    setData() {
      let temp = this.accountInfo.liveConfig;
      if (temp.item && temp.item.length) {
        temp = temp.item.filter((item) => FILTER_TYPE.indexOf(item.type) > -1);
      }
      this.menus = temp.map((item) => Object.assign({}, item));
      console.log(">>> menus", this.menus);
    },
    toggleShow(index) {
      this.menus[index].enable = !this.menus[index].enable;
      // if (this.menus[index].layer === "H" && this.menus[index].enable) {
      //   let idx = this.menus.findIndex((item) => item.type === "camera");
      //   if (idx > -1) {
      //     this.menus[idx].enable = false;
      //   }
      // } else if (
      //   this.menus[index].type === "camera" &&
      //   this.menus[index].enable
      // ) {
      //   let idx = this.menus.findIndex((item) => item.layer === "H");
      //   if (idx > -1) {
      //     this.menus[idx].enable = false;
      //   }
      // }
    },
    selectAct(index) {
      this.currentActIdx = index;
    },
    up() {
      if (this.currentActIdx > 0) {
        let [temp] = this.menus.splice(this.currentActIdx, 1);
        this.menus.splice(--this.currentActIdx, 0, temp);
      }
    },
    down() {
      if (this.currentActIdx < this.menus.length - 1) {
        let [temp] = this.menus.splice(this.currentActIdx, 1);
        this.menus.splice(++this.currentActIdx, 0, temp);
      }
    },
    showSet() {
      this.isShowSet = !this.isShowSet;
      if (this.isShowSet) {
        this.oldScale = this.contentScale;
        this.contentScale = 0.66;
      }
      if (this.isShowSet) {
        if (this.menus[this.currentActIdx].layer) {
          this.sourceType = this.menus[this.currentActIdx].layer;
        } else {
          this.sourceType = "W";
        }
        this.scale = this.menus[this.currentActIdx].scale || 1;
        this.layerLeft =
          parseFloat(this.menus[this.currentActIdx].left) * 100 || 0;
        this.layerTop =
          parseFloat(this.menus[this.currentActIdx].top) * 100 || 0;
        if (this.menus[this.currentActIdx].type !== "camera") {
          this.fetchLayer(this.menus[this.currentActIdx].layer);
        }
      }
    },
    closePop() {
      this.contentScale = this.oldScale;
      this.oldScale = 0;
    },
    changeSourceType(typeInfo) {
      this.menus[this.currentActIdx].layer = typeInfo.value;
      this.menus[this.currentActIdx].label = typeInfo.label;
      if (this.sourceType !== typeInfo.value) {
        this.fetchLayer(typeInfo.value);
      }
      this.sourceType = typeInfo.value;
    },
    onScaleChange() {
      this.menus[this.currentActIdx].scale = this.scale;
    },
    onLeftChange() {
      let temp = this.menus[this.currentActIdx];
      temp.left = `${this.layerLeft / 100}rem`;
    },
    onTopChange() {
      let temp = this.menus[this.currentActIdx];
      temp.top = `${this.layerTop / 100}rem`;
    },
    save() {
      this.$dialog
        .confirm({
          message: "确定保存当前配置？下场开播生效",
        })
        .then(() => {
          this.pushSave();
        })
        .catch(() => {});
    },
    async pushSave() {
      let item = [];
      item = this.menus.slice(0);
      let temp = this.accountInfo.liveConfig;
      if (temp.item && temp.item.length) {
        temp = temp.item.filter(
          (item) => FILTER_TYPE.indexOf(item.type) === -1
        );
      }
      if (temp.length) {
        item = item.concat(temp);
      }
      let ret = await this.$http.put(
        `devices/${this.accountInfo.id}/live-configs/${this.pid}`,
        {
          item: item,
        }
      );
      this.setCurrentPid(
        Object.assign({}, this.accountInfo, { liveConfig: ret })
      );
    },
    toggleExpand() {
      this.isExpand = !this.isExpand;
    },
    parseStyle(item) {
      let temp = {};
      if (item.height) {
        temp.height = item.height;
      }
      if (item.width) {
        temp.width = item.width;
      }
      if (item.top) {
        temp.top = item.top;
      }
      if (item.left) {
        temp.left = item.left;
      }
      if (item.right) {
        temp.right = item.right;
      }
      if (item.bottom) {
        temp.bottom = item.bottom;
      }
      let transform = [];

      if (item.scale) {
        transform.push(`scale(${item.scale})`);
      }
      if (item.rotate) {
        transform.push(` rotate(${item.rotate})`);
      }
      temp.transform = transform.join(" ");
      return temp;
    },
    add() {
      let temp = Object.assign({}, this.menus[0], {
        enable: true,
        order: "default",
        label: "背景",
        source: [],
        left: "0rem",
        right: null,
        bottom: null,
        scale: 1,
        rotate: 0,
        top: "0rem",
        width: "3.6rem",
        height: "8rem",
        chromakey: null,
      });
      delete temp.id;
      delete temp._id;
      delete temp._v;
      this.menus.push(temp);
      this.currentActIdx = this.menus.length - 1;
      this.showSet();
    },
    async fetchLayer(layer) {
      let params = { layer };
      let ret = await this.$http.post(`/live-assets/search`, params);
      this.layers = ret;
    },
    getRect(url, type) {
      return new Promise((resolve, reject) => {
        if (type.includes("image/")) {
          let img = new Image();
          img.src = url;
          img.onload = () => {
            resolve({ width: img.width, height: img.height });
          };
        } else if (type.includes("video/")) {
          var video = document.createElement("video");
          // 元数据已加载
          video.muted = true;
          video.autoplay = false;
          video.src = url;
          //微信浏览器兼容问题，不然无法出发 loadedmetadata
          video.play().then(() => video.pause());
          video.addEventListener("loadedmetadata", () => {
            resolve({ width: video.videoWidth, height: video.videoHeight });
          });
        }
      });
    },
    async selLayer(layer) {
      let temp = this.menus[this.currentActIdx];
      if (layer.source.mime.includes("image/")) {
        temp.type = "image";
      }
      if (layer.source.mime.includes("video/")) {
        temp.type = "video";
        temp.chromakey = {
          color: "#04f404",
          similarity: 0.43,
          smoothness: 0.08,
        };
      }
      temp.source = [layer.source];
      if (!temp.id) {
        let ret = await this.getRect(layer.source.url, layer.source.mime);
        //计算宽高比，选择宽高比
        if (ret.width / ret.height > 72 / 160) {
          if (ret.width > 720) {
            temp.width = `${720 / 100}rem`;
            temp.height = `${(ret.height * 720) / (ret.width * 100)}rem`;
          } else {
            temp.width = `${ret.width / 100}rem`;
            temp.height = `${ret.height / 100}rem`;
          }
        } else {
          if (ret.height > 1600) {
            temp.width = `${(ret.width * 1600) / (ret.height * 200)}rem`;
            temp.height = `8rem`;
          } else {
            temp.width = `${ret.width / 100}rem`;
            temp.height = `${ret.height / 100}rem`;
          }
        }
        temp.layer = this.sourceType;
      }
    },
    deletelayer(index) {
      this.$dialog
        .confirm({
          message: `确定删除「${this.menus[index].label}」图层吗？下场开播生效`,
        })
        .then(() => {
          this.menus.splice(index, 1);
          if (this.currentActIdx === index) {
            this.currentActIdx = this.menus.length - 1;
          }
          this.pushSave();
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.template-wrap {
  * {
    user-select: none;
  }
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding: r(2);
  background: #202020;
  .preview {
    position: absolute;
    right: r(1);
    top: r(1);
    z-index: 2;
  }
  .content {
    height: r(1600);
    position: absolute;
    left: 50%;
    top: 0;
    width: 100%;
    transform-origin: top left;
    overflow: hidden;
    border: r(4) solid #6f6c6c;
    transition: scale ease-out 0.3s;
    .wrap {
      position: absolute;
      transform-origin: left top;
      &.camera {
        background: url(https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/webp/default_camera.png)
          center no-repeat;
        background-size: contain;
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      // &.select {
      //   animation: border-blink 300ms ease-in-out 10;
      // }
    }
  }
  .top-wrap {
    position: absolute;
    left: r(2);
    top: r(10);
    padding: r(10);
    padding-left: r(10);
    background: rgba(0, 0, 0, 0.5);
    border-radius: r(8);
    font-size: r(30);
    color: #fff;
    z-index: 4;
    max-width: r(300);
    transition: transform ease-out 0.3s;
    &.expand {
      transform: translateX(-100%);
    }
    .item {
      border: r(2) solid transparent;
      width: 100%;
      padding: r(8);
      display: flex;
      justify-content: space-between;
      line-height: r(30);
      &.act {
        background: #644afa;
        border: r(2) solid #b0b0e0;
        border-radius: r(8);
      }
      > span {
        flex: 1;
      }
      .item-opt {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .delete-icon {
        margin-left: r(10);
      }
    }
    .opt {
      position: absolute;
      height: r(50);
      bottom: r(-60);
      left: 0rem;
      font-size: r(30);
      line-height: r(40);
      display: flex;
      justify-content: space-around;
      color: #fff;
      width: 100%;
      .opt-item {
        height: r(40);
        width: r(40);
        text-align: center;
        background: rgba(0, 0, 0, 0.5);
        border-radius: r(4);
        &.up {
          transform: rotate(180deg);
        }
      }
    }
  }
  .open {
    position: absolute;
    right: r(0);
    top: r(10);
    height: r(50);
    padding: 0 r(20);
    line-height: r(50);
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: r(4);
    color: #fff;
    font-size: r(30);
    z-index: 3;
  }
  .save {
    position: absolute;
    right: r(0);
    top: r(10);
    padding: r(10) r(20);
    line-height: r(50);
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: r(4);
    color: #fff;
    font-size: r(40);
    z-index: 3;
  }
  .set-pop-wrap {
    background: rgba(0, 0, 0, 1);
    font-size: 3rem;
    .setting-wrap {
      height: r(300);
      .tabs {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        .item {
          background: #fff;
          padding: r(10) r(20);
          font-size: r(20);
          border-right: r(2) solid #6f6c6c;
          &:last-child {
            border-bottom-right-radius: r(4);
            border-top-right-radius: r(4);
          }
          &.act {
            background: #644afa;
            color: #fff;
          }
          :deep {
            .van-stepper {
              display: flex;
              align-items: center;
            }
          }
        }
      }
      .source {
        width: 100%;
        height: r(200);
        box-sizing: border-box;
        overflow-x: auto;
        display: flex;
        flex-wrap: nowrap;
        padding: r(10);
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
        .item-wrap {
          width: r(180);
          height: r(180);
          overflow: hidden;
          text-align: center;
          flex-shrink: 0;
          margin-right: r(10);
          position: relative;
          &.act {
            border: r(4) solid #644afa;
          }
          img {
            max-width: 100%;
            max-height: 100%;
          }
          video {
            width: 100%;
            height: 100%;
            display: block;
            &::-webkit-media-controls-encloseure {
              display: none !important;
            }
          }
        }
      }
      .opt-wrap {
        margin-top: r(20);
        margin-bottom: r(20);
        display: flex;
        align-items: center;
        justify-content: space-around;
        .scale {
          display: flex;
          align-items: center;
          .label {
            color: #fff;
            font-size: r(20);
            flex-shrink: 0;
            margin-right: r(20);
          }
        }
        :deep {
          .van-stepper__minus,
          .van-stepper__plus {
            width: r(40);
            height: r(40);
          }
          .van-stepper__input {
            min-width: r(40);
            height: r(40);
          }
        }
      }
    }
  }
  :deep .set-overlay {
    background: transparent;
  }
  .prew-wrap {
    width: 100%;
    height: 100%;
    background: #202020;
    .preview-content {
      width: r(720);
      height: r(1600);
      transition: scale ease-out 0.3s;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      transform-origin: top left;
      border: r(4) solid #6f6c6c;
    }
    .cancel {
      position: absolute;
      left: r(10);
      top: r(10);
      z-index: 3;
    }
    .save {
      position: absolute;
      right: r(10);
      top: r(10);
      z-index: 4;
    }
  }
}
@keyframes border-blink {
  0% {
    border-width: 2px;
    border-style: solid;
    border-color: red; /* 初始状态为红色 */
  }
  50% {
    border-width: 2px;
    border-style: solid;
    border-color: transparent; /* 到一半时边框变为透明 */
  }
  100% {
    border-width: 2px;
    border-style: solid;
    border-color: red; /* 结束时边框再次变为红色 */
  }
}
</style>